<template>
  <b-container fluid>
    <b-row class="">
        <b-col sm="12">
          <card class="">
            <template v-slot:headerTitle>
              <h4 class="card-title"><b>LISTA DE TIPOS DE COMPETENCIAS</b></h4>
            </template>
            <div class="mb-3 mt-3">
             
                <div class="px-1 py-3 mt-3 mx-3 bg-dark border-radius card mb-2">
                  <div class="row ">
                    <div class="col-11 text-left mt-2 pl-4 ">
                      <b> Descripcion: </b>
                    </div>
                    
                    <div class="col-1 text-center pl-1">
                      <button class="btn btn-info pl-3 mr-3">
                        <i class="fas fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>                        
                <div class="bg-dark border-radius mb-2 p-3 mx-3 card">
                  
                  <div class="row mt-2">
                    
                    <div class="col-10 text-left pr-0">
                      <input required placeholder="Escriba el Nombre de la Competencia..." type="text" class="form-control" id=" "  :disabled="disabled == 0" />
                      <!-- <input required placeholder="Escriba el Nombre de la Competencia..." type="text" class="form-control" id=" " /> -->
                      
                    </div>
                    
                    <div class="col-2 text-right pr-3 pl-0" v-if="disabled==0">
                      <!-- <button class="btn btn-secondary pl-3 mt-1 mr-2">
                        <i class="fas fa-minus"></i>
                      </button> -->
                      <button @click="disabled = (disabled + 1) " class="btn btn-success pl-3 mt-1 mr-4">
                        <i class="fas fa-pen"></i>
                      </button>
                      <button class="btn btn-secondary pl-3 mt-1 mr-3">
                        <i class="fas fa-trash"></i>
                      </button>
                    </div>
                    <div class="col-2 text-right pr-3 pl-0" v-if="disabled==1">
                      <!-- <button class="btn btn-secondary pl-3 mt-1 mr-2">
                        <i class="fas fa-minus"></i>
                      </button> -->
                      <button @click="disabled = (disabled - 1) " class="btn btn-warning mr-3 mt-1 ">
                        <i class="fas fa-archive "><span class="ml-2">Guardar</span></i>
                      </button>
                      
                    </div>
                  </div> 
                  <!-- <div class="row mt-2">
                    
                    <div class="col-10 text-left ">
                      <input required placeholder="Escriba el Nombre de la Competencia..." type="text" class="form-control" id=" " />
                      
                    </div>
                    
                    <div class="col-2 text-right pr-3">
                      <button class="btn btn-secondary pl-3 mt-1 mr-3">
                        <i class="fas fa-minus"></i>
                      </button>
                      <button class="btn btn-secondary pl-3 mt-1 mr-3">
                        <i class="fas fa-minus"></i>
                      </button>
                    </div>
                  </div> -->
                </div>
                
            </div>
            
            
            
            
            
          </card>
        </b-col>
        
      </b-row>
  </b-container>
</template>
<script>
import { core } from "../../../config/pluginInit";
export default {
  name: "DataTable",
  data() {
    return {
      disabled: 0,
      tableName: "Persona",
      listaItems: [],
      persona: {},
      empresaId: "",
      listaPersonaUbicacion: [],
      rowModal: { nombre: "", titulo: "", empresa: { nombre: "" } },
    };
  },
  async mounted() {
    this.persona = JSON.parse(localStorage.getItem("setPersonaAct"));
    this.empresaId = this.persona.empresa.id;
    await this.getData();
    await this.getPersonaUbicacion();
    core.index();
    window.$("#datatable_evaluaciones").DataTable({
      language: {
        url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
      },
    });
  },
  methods: {
    showModal(item) {
      this.rowModal = item;
      this.$bvModal.show("my-modal");
    },
    obtenerUbicacionPersona(item) {
      let io = this.listaPersonaUbicacion.find((x) => x.persona.id == item.id);
      if (io > 0) {
        let result = this.listaPersonaUbicacion.indexOf(io);
        console.log("cargo ", result);
      }
      return io ? io.cargo.nombre : "";
    },
    async getPersonaUbicacion() {
      try {
        //   console.log("get empresas...");
        let res = await this.$store.dispatch("hl_get", {
          path: "PersonaUbicacion/List/" + this.empresaId,
        });
        console.log("post empresas...", res);
        if (res.length > 0) {
          this.listaPersonaUbicacion = res;
        } else {
          this.listaPersonaUbicacion = [];
        }
      } catch (error) {
        this.listaPersonaUbicacion = [];
        console.log("err", error);
      }
    },
    async getData() {
      console.log(this.persona.empresa.id);
      try {
        console.log("get ciclo ev...");
        let res = await this.$store.dispatch("hl_get", {
          path: "CicloEvaluacion/ListCicloEvaluacion/" + this.empresaId,
        });
        console.log("...", res);
        if (res.length > 0) {
          this.listaItems = res;
        } else {
          this.listaItems = [];
        }
      } catch (error) {
        this.listaItems = [];
        console.log("err", error);
      }
    },
  },
};
</script>
<style scoped>
.bold {
  font-weight: bold !important;
}
</style>
